<script setup lang="ts">
import TextInput from '@/Components/TextInput.vue'
import HeroIconComponent from '@/Components/HeroIconComponent.vue'
import { PlusIcon } from '@heroicons/vue/20/solid'
import InputLabel from '@/Components/InputLabel.vue'
import { computed, ref } from 'vue'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import { StakeholderDTO } from '@Types'
import { useAlertModalStore } from '@/Pages/Location/stores/useAlertModalStore'

const store = useLocationStore()
const alertStore = useAlertModalStore()
let currentAdd = -1

const addStakeholder = () => {
    let exists = store.form.stakeholders.findIndex(
        (d) =>
            d.name?.trim().toLowerCase() ===
            newStakeholderName.value.trim().toLowerCase()
    )
    if (exists !== -1) {
        alertStore.title = 'Stakeholder bestaat al'
        alertStore.message = 'Er bestaat al een stakeholder met deze naam...'
        alertStore.positiveButtonText = 'Ok'
        alertStore.hasCancelButton = false
        alertStore.show()
        return
    }
    store.form.stakeholders.unshift({
        name: newStakeholderName.value,
        id: currentAdd,
    })
    currentAdd -= 1
    newStakeholderName.value = ''
}

const removeStakeholder = (stakeholder: StakeholderDTO) => {
    store.form.stakeholders = store.form.stakeholders.filter(
        (d) => d.id !== stakeholder.id
    )
}

const newStakeholderName = ref<string>('')
const canAdd = computed(() => (newStakeholderName.value?.length ?? 0) > 0)
</script>

<template>
    <div class="flex flex-col px-10">
        <InputLabel value="Stakeholders" />
        <div class="flex">
            <TextInput
                class="mb-3 w-full"
                v-model="newStakeholderName"
                placeholder="Nieuwe stakeholder"
                @keydown.enter="addStakeholder"
            />
            <button
                type="button"
                @click="addStakeholder"
                :disabled="!canAdd"
                class="ml-1 mt-1.5 h-8 w-8 rounded-full enabled:bg-gradient disabled:cursor-not-allowed disabled:bg-gray-300"
            >
                <PlusIcon
                    class="h-8 w-8 rounded-full p-1 align-middle text-white"
                />
            </button>
        </div>
        <div class="flex flex-wrap gap-x-2">
            <div
                v-for="option in store.form.stakeholders"
                :key="option?.id ?? 0"
                class="flex"
            >
                <div class="stakeholder-tag">
                    <input type="text" v-model="option.name" />
                    <button
                        type="button"
                        class="cursor-pointer"
                        @click="removeStakeholder(option)"
                    >
                        <HeroIconComponent icon="XMarkIcon" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.stakeholder-tag {
    @apply my-1 flex h-6 w-auto cursor-text rounded bg-gradient align-middle font-semibold text-white hover:bg-primary;
}

.stakeholder-tag input {
    border: none;
    background: transparent;
    outline: none;
    width: auto;
}

.stakeholder-tag span {
    cursor: pointer;
    margin-left: 5px;
}
</style>
