<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import TextInput from '@/Components/TextInput.vue'
import InputError from '@/Components/InputError.vue'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import VueMultiselect from 'vue-multiselect'
import { computed, ref } from 'vue'
import { LocationType } from '@Types'

const store = useLocationStore()
const options = computed(() => store.locationTypes)

const model = ref(
    options.value?.find((option) => option.key === store.form.locationType)
)

const modelChanged = () => {
    store.form.locationType = model.value?.key ?? LocationType.CureLocation
}
</script>

<template>
    <div class="flex max-w-2xl flex-col px-10">
        <div class="flex flex-col">
            <InputLabel for="name" value="Naam*" />
            <TextInput
                id="name"
                type="text"
                class="block"
                v-model="store.form.name"
                required
                autocomplete="name"
                placeholder="Naam locatie"
            />

            <InputError class="mt-2" :message="store.form.errors.name" />
        </div>

        <div class="mt-3 flex flex-col">
            <InputLabel for="address" value="Adres" />
            <div class="mb-3 grid grid-cols-1 gap-3 sm:grid-cols-2">
                <TextInput
                    id="address"
                    type="text"
                    class="block sm:col-span-2"
                    v-model="store.form.address"
                    required
                    autocomplete="address"
                    placeholder="Straat & huisnummer"
                />
                <span class="sm:hidden" />
                <TextInput
                    id="zipcode"
                    type="text"
                    class="block"
                    v-model="store.form.zipcode"
                    required
                    autocomplete="zipcode"
                    placeholder="zipcode"
                />
                <TextInput
                    id="city"
                    type="text"
                    class="block"
                    v-model="store.form.city"
                    required
                    autocomplete="city"
                    placeholder="Plaats"
                />
            </div>
            <InputError class="mt-2" :message="store.form.errors.address" />
        </div>
        <div class="grid-cols- mt-4 grid">
            <InputLabel for="locationType" value="Type locatie" />
            <div class="flex justify-end">
                <VueMultiselect
                    id="locationType"
                    class="mt-2"
                    :options="options"
                    track-by="key"
                    label="value"
                    :show-labels="false"
                    :allow-empty="true"
                    v-model="model"
                    :multiple="false"
                    placeholder=""
                    @select="modelChanged"
                    :disabled="(store.form.id ?? 0) > 0"
                    lang="nl"
                />
            </div>
        </div>
    </div>
</template>
