<script setup lang="ts">
import * as HeroIcons from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const { icon } = defineProps<{
    icon: string
}>()

/** @ts-expect-error  ignore type checks here. */
const iconComponent = computed(() => HeroIcons[icon])
</script>

<template>
    <component :is="iconComponent" class="h-6 w-6" />
</template>

<style scoped></style>
