import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAlertModalStore = defineStore('alertModalStore', () => {
    const open = ref(false)
    const message = ref<string>('')
    const title = ref<string>('')
    const positiveButtonText = ref<string>('OK')
    const cancelButtonText = ref<string>('Annuleren')
    const positiveEvent = new Event('alert-modal-positive-clicked')
    const negativeEvent = new Event('alert-modal-negative-clicked')
    const hasCancelButton = ref(false)

    const triggerPositiveEvent = () => {
        hide()
        window.dispatchEvent(positiveEvent)
    }

    const triggerNegativeEvent = () => {
        hide()
        window.dispatchEvent(negativeEvent)
    }

    const show = () => (open.value = true)
    const hide = () => {
        open.value = false
        message.value = ''
        title.value = ''
        positiveButtonText.value = 'OK'
        cancelButtonText.value = 'Annuleren'
        hasCancelButton.value = false
    }

    return {
        triggerNegativeEvent,
        triggerPositiveEvent,
        show,
        hide,
        title,
        open,
        hasCancelButton,
        message,
        positiveButtonText,
        cancelButtonText,
    }
})
