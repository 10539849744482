import './bootstrap'
import '../css/app.css'
import * as Sentry from '@sentry/vue'
import { createApp, DefineComponent, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import LocationInfo from '@/Pages/Location/Partials/LocationInfo.vue'
import LocationFloor from '@/Pages/Location/Partials/LocationFloor.vue'
import FloorRow from '@/Pages/Location/Partials/FloorRow.vue'
import { createPinia } from 'pinia'
import TimeField from '@/Components/Fields/TimeField.vue'
import HoursMinutesField from '@/Components/Fields/HoursMinutesField.vue'
import SingleChoiceField from '@/Components/Fields/SingleChoiceField.vue'
import SingleChoiceWithNotesField from '@/Components/Fields/SingleChoiceWithNotesField.vue'
import MultiChoiceField from '@/Components/Fields/MultiChoiceField.vue'
import TextField from '@/Components/Fields/TextField.vue'
import TextareaField from '@/Components/Fields/TextareaField.vue'

import NumberField from '@/Components/Fields/NumberField.vue'
import SwitchField from '@/Components/Fields/SwitchField.vue'
import DateField from '@/Components/Fields/DateField.vue'
import LocationElevators from '@/Pages/Location/Partials/LocationElevators.vue'
import LocationData from '@/Pages/Location/Partials/LocationData.vue'
import LocationStakeholders from '@/Pages/Location/Partials/LocationStakeholders.vue'
import CheckBoxSwitchField from "@/Components/Fields/CheckBoxSwitchField.vue";

const appName = import.meta.env.VITE_APP_NAME || 'CureTrack'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob<DefineComponent>('./Pages/**/*.vue')
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(createPinia())
            .use(ZiggyVue)
            .component('LocationInfo', LocationInfo)
            .component('LocationFloor', LocationFloor)
            .component('LocationElevators', LocationElevators)
            .component('LocationData', LocationData)
            .component('LocationStakeholders', LocationStakeholders)
            .component('FloorRow', FloorRow)
            .component('numberField', NumberField)
            .component('timeField', TimeField)
            .component('hoursMinutesField', HoursMinutesField)
            .component('singleChoiceField', SingleChoiceField)
            .component('singleChoiceWithNotesField', SingleChoiceWithNotesField)
            .component('multipleChoiceField', MultiChoiceField)
            .component('textField', TextField)
            .component('textareaField', TextareaField)
            .component('checkboxField', SwitchField)
            .component('dateField', DateField)
            .component('checkBoxSwitchField', CheckBoxSwitchField)

        Sentry.init({
            app: app,
            dsn: 'https://ec19e3c11d6ebfe1abe40582d7da89b8@o246235.ingest.us.sentry.io/4507933503193088',
            integrations: [
                Sentry.browserTracingIntegration({}),
                Sentry.browserProfilingIntegration(),
            ],
            // Tracing
            tracesSampleRate: 0.01,
        })

        app.mount(el)

        return app
    },
    progress: {
        color: '#4B5563',
    },
})
