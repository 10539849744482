<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { QuestionDataObject } from '@Types'
import VueMultiselect from 'vue-multiselect'
import { SurveyStore } from '@/Pages/Location/stores/useSurveyStore'
import SwitchCheckBox from '@/Components/SwitchCheckBox.vue'

const props = defineProps<{
    question: QuestionDataObject
    store: SurveyStore
    answer?: string
    number: string
}>()

const computedAnswer = computed(() =>
    props.store.answerForQuestion<number>(props.question.id)
)
const computedComment = computed(() =>
    props.store.commentForQuestion(props.question.id)
)

const computedIncludeInReport = computed(() =>
    props.store.includeInReportForQuestion(props.question.id)
)

const comment = ref(computedComment.value ?? '')
const model = ref(computedAnswer.value ?? '')
const switchModel = ref(computedIncludeInReport.value ?? false)
const id = computed(() => 'question_' + props.question.type + props.question.id)

const modelChanged = () => {
    props.store.setAnswerForQuestion(props.question.id, model.value)
}

const didActivateReportOnce = ref(false)
const commentChanged = () => {
    props.store.setCommentForQuestion(props.question.id, comment.value)
    updateHeight()

    if (commentLength.value > 0 && !didActivateReportOnce.value) {
        didActivateReportOnce.value = true
        props.store.setIncludeInReportForQuestion(props.question.id, true)
    }
}
const textarea = ref<HTMLTextAreaElement | null>(null)
const updateHeight = () => {
    if (textarea.value) {
        textarea.value.style.height = 'auto'
        textarea.value.style.height = `${textarea.value.scrollHeight}px`
    }
}

const switchModelChanged = () => {
    props.store.setIncludeInReportForQuestion(
        props.question.id,
        switchModel.value
    )
}

watch(computedAnswer, () => {
    model.value = computedAnswer.value ?? 0
})

watch(computedComment, () => {
    comment.value = computedComment.value ?? ''
})

watch(computedIncludeInReport, () => {
    switchModel.value = computedIncludeInReport.value ?? false
})

onMounted(() => {
    setTimeout(updateHeight, 50)
})

const maxCommentLength = 2000
const commentLength = computed(() => comment.value.length)
</script>

<template>
    <div class="mb-4 flex flex-col">
        <InputLabel :value="number + '. ' + question.text" />
        <VueMultiselect
            :id="id"
            class="mt-2"
            :options="question.choices"
            :show-labels="false"
            :allow-empty="true"
            v-model="model"
            :multiple="false"
            placeholder=""
            @select="modelChanged"
            lang="nl"
        />
        <div class="mt-2 flex flex-row space-x-2">
            <textarea
                :id="'comment-textarea' + question.id"
                ref="textarea"
                type="text"
                class="col-span-2 block flex-grow rounded border-gray-200"
                v-model="comment"
                placeholder="Opmerking"
                @input="commentChanged"
                lang="nl"
                spellcheck="false"
                maxlength="2000"
            />
            <div class="mt-2 flex flex-col">
                <InputLabel
                    class="flex items-center justify-start"
                    for="report-switch"
                    value="Rapportage"
                />
                <div class="my-auto flex justify-start">
                    <SwitchCheckBox
                        id="report-switch"
                        v-model="switchModel"
                        @update:modelValue="switchModelChanged"
                    />
                </div>
            </div>
        </div>
        <span class="text-sm text-gray-400">{{
            commentLength + '/' + maxCommentLength
        }}</span>
    </div>
</template>
