<template>
	<svg height="298.9" viewBox="0 0 114.46 84.72" width="403.8" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m53.86 12.22a11 11 0 0 0 2.22 5.66c-2.58-1.22-4.6-4.21-5-7.9-.51-5.08 2.26-9.54 6.21-9.98a6.73 6.73 0 0 1 5.9 2.81 5.73 5.73 0 0 0 -3.13-.56c-3.95.44-6.73 4.89-6.2 9.97zm3.57 20.28c30.91 0 55.66-21.41 55.32-23.61a.3.3 0 0 0 0-.1c-6.35 8.54-28.59 14.84-55 14.84s-48.75-6.3-55.14-14.84a.31.31 0 0 0 0 .22c.39 2.75 24.39 23.49 54.82 23.49zm-35.33 49.62a41.55 41.55 0 0 0 11-1.48l.55-.13-.41-6.7-.85.33a25.56 25.56 0 0 1 -9.01 1.86 13.27 13.27 0 0 1 -6.95-1.78 10.57 10.57 0 0 1 -3.79-4.15c-5.93-12 3.46-19.2 9.94-19.2a18.92 18.92 0 0 1 9.42 2.56l1.12.6.16-1.63.55-5.49-.15 1.53.15-1.53-.52-.16a39.87 39.87 0 0 0 -11.31-1.95c-13.59 0-22 8-22 19 .84 13.49 12.26 18.32 22.1 18.32zm50-11.57c.63-7.67-7-9.35-11.83-10.7-3.44-1-5.58-1.66-5.74-3.35a8.46 8.46 0 0 1 -.14-1c-.06-2 2-4.6 6.15-4.6a19.31 19.31 0 0 1 8 1.65l1.09.48.53-6.63-.49-.17a32.22 32.22 0 0 0 -9.84-1.42c-10.08-.01-16.27 3.88-16.83 10.86.66 7.25 6.58 9 11.4 10.51 3.83 1.15 5.76 2 6.28 4.19a6.77 6.77 0 0 1 -.13 3.54 2.74 2.74 0 0 1 -1 1.19c-.86.58-2.17.93-4.48.93a21.2 21.2 0 0 1 -10-2.38l-.86-.46-.75 7.22.24.08a39.7 39.7 0 0 0 11.32 1.64c10.65-.01 16.49-4.01 17.12-11.58zm8.28-3.9c0 9.83 6.2 15.47 17 15.47s17-5.63 17-15.45v-21.23h-11.19v22.15c0 5.76-1.83 8.43-5.75 8.43s-5.76-2.67-5.76-8.43v-22.15h-11.27z"
			fill="#009036"/>
		<path
			d="m59.21 18.44a5.93 5.93 0 0 1 -3.13-.56 11 11 0 0 1 -2.22-5.66c-.53-5.08 2.25-9.53 6.2-9.94a5.73 5.73 0 0 1 3.13.56 10.87 10.87 0 0 1 2.22 5.65c.53 5.09-2.24 9.51-6.2 9.95zm53.54-9.55c.34 2.2-24.41 23.61-55.32 23.61-30.43 0-54.43-20.74-54.85-23.5.34 3 24.62 27.9 55.08 27.9 30.92 0 55.46-25.69 55.09-28.01zm-79.64 45.11.16-1.63zm.59 26.48v.82zm.15 2.56-.1-1.74v-.82l-.55.13a41.55 41.55 0 0 1 -11 1.48c-9.94.03-21.36-4.8-22.2-18.27v1.08c0 7.59 3.22 12.64 7.74 15.74a20.2 20.2 0 0 0 2 1.21 26.8 26.8 0 0 0 12.36 2.87 40.61 40.61 0 0 0 5.58-.36 54 54 0 0 0 5.47-1.11zm-1.85-29.61a18.92 18.92 0 0 0 -9.41-2.54c-6.48 0-15.87 7.24-9.94 19.2a2 2 0 0 0 -.13-.39 11.85 11.85 0 0 1 -.93-4.78 14 14 0 0 1 .32-3c0-.11 0-.23.08-.34s0-.23.08-.35a10.66 10.66 0 0 1 1.25-2.87l.17-.24.24-.36a10.21 10.21 0 0 1 5.65-3.89 10 10 0 0 1 3.21-.42 18.86 18.86 0 0 1 9.34 2.55h.07l.87.48.24-2.48zm28.59-2.54c-4.17 0-6.21 2.65-6.15 4.6a8.46 8.46 0 0 0 .14 1c0-.06 0-.12 0-.19a2.31 2.31 0 0 1 .23-1 1.62 1.62 0 0 1 .1-.18v-.07a.9.9 0 0 1 .15-.19l.06-.07a1.22 1.22 0 0 1 .17-.15l.15-.12c.86-.62 2.35-.93 4.55-1h.56a19.11 19.11 0 0 1 8 1.65l.88.39.25-2.56-1.09-.48a19.31 19.31 0 0 0 -8.01-1.63zm11.61 20.87c0-.42 0-.82-.06-1.21-.63 7.57-6.47 11.57-17 11.57a39.7 39.7 0 0 1 -11.34-1.64l-.25-.08-.26 2.52.51.17a39 39 0 0 0 9.56 1.58h1.76c11.18.05 17.08-4.43 17.08-12.91zm-11.62 2.16a4.64 4.64 0 0 0 .32-1.8 7.82 7.82 0 0 0 -.18-1.76c-.52-2.23-2.45-3-6.28-4.19-4.82-1.46-10.74-3.26-11.44-10.51a12.36 12.36 0 0 0 0 1.24c0 8.4 6.38 10.25 11.46 11.87.75.23 1.44.44 2.07.65l.47.17h.07l.44.17.21.09h.07a4.55 4.55 0 0 1 2.63 2 2.53 2.53 0 0 1 .2 1.15s0 .2 0 .24a3.5 3.5 0 0 1 -.32 1.17 2.9 2.9 0 0 0 .28-.49zm53.88-4.67v-2.58c0 9.82-6.22 15.45-17 15.45s-17-5.64-17-15.47v2.6c0 9.83 6.2 15.47 17 15.47s17-5.64 17-15.47z"
			fill="#b1c800"/>
	</svg>
</template>
