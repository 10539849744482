<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import TextInput from '@/Components/TextInput.vue'
import { computed, ref, watch } from 'vue'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import HeroIconComponent from '@/Components/HeroIconComponent.vue'
import { PlusIcon } from '@heroicons/vue/20/solid'
import { DepartmentDTO, FloorDTO } from '@Types'
import { useAlertModalStore } from '@/Pages/Location/stores/useAlertModalStore'

const props = defineProps<{
    floor: FloorDTO
}>()

const floorRef = ref(props.floor)

watch(props.floor, () => {
    floorRef.value = props.floor
})

const store = useLocationStore()
const alertStore = useAlertModalStore()
let currentAdd = -1

const addDepartment = () => {
    let exists = floorRef.value.departments.findIndex(
        (d) =>
            d.name.trim().toLowerCase() ===
            newDepartmentName.value.trim().toLowerCase()
    )
    if (exists !== -1) {
        alertStore.title = 'Afdeling bestaat al'
        alertStore.message = 'Er bestaat al een afdeling met deze naam...'
        alertStore.positiveButtonText = 'Ok'
        alertStore.hasCancelButton = false
        alertStore.show()
        return
    }
    floorRef.value.departments.push({
        name: newDepartmentName.value,
        id: currentAdd,
    })

    currentAdd -= 1
    store.updateFloor(floorRef.value)
    newDepartmentName.value = ''
}

const removeDepartment = (department: DepartmentDTO) => {
    floorRef.value.departments = floorRef.value.departments.filter(
        (d) => d.id !== department.id
    )
    store.updateFloor(floorRef.value)
}

const newDepartmentName = ref<string>('')
const canAdd = computed(() => (newDepartmentName.value?.length ?? 0) > 0)
</script>

<template>
    <div class="flex w-full flex-col gap-x-3 border-b pb-2 sm:flex-row">
        <div class="">
            <InputLabel for="city" value="Verdieping" />
            <TextInput
                id="name"
                type="text"
                class="block"
                v-model="floorRef.name"
                required
                autocomplete="name"
                placeholder="Verdieping"
            />
        </div>

        <div class="">
            <InputLabel for="city" value="Aantal afdelingen" />
            <TextInput
                id="numberOfDepartments"
                type="number"
                class="block"
                v-model="floorRef.numberOfDepartments"
                autocomplete="numberOfDepartments"
                placeholder="Aantal afdelingen"
            />
        </div>

        <div class="flex flex-1 flex-col">
            <InputLabel value="Afdelingen" />
            <div class="flex">
                <TextInput
                    class="mb-3 w-full"
                    v-model="newDepartmentName"
                    placeholder="Nieuwe afdeling"
                    @keydown.enter="addDepartment"
                />
                <button
                    type="button"
                    @click="addDepartment"
                    :disabled="!canAdd"
                    class="ml-1 mt-1.5 h-8 w-8 rounded-full enabled:bg-gradient disabled:cursor-not-allowed disabled:bg-gray-300"
                >
                    <PlusIcon
                        class="h-8 w-8 rounded-full p-1 align-middle text-white"
                    />
                </button>
            </div>
            <div class="flex flex-wrap gap-x-2">
                <div
                    v-for="option in floorRef.departments"
                    :key="option.id ?? 0"
                    class="flex"
                >
                    <div class="department-tag">
                        <input type="text" v-model="option.name" />
                        <button
                            type="button"
                            class="cursor-pointer"
                            @click="removeDepartment(option)"
                        >
                            <HeroIconComponent icon="XMarkIcon" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.department-tag {
    @apply my-1 flex h-6 w-auto cursor-text rounded bg-gradient align-middle font-semibold text-white hover:bg-primary;
}

.department-tag input {
    border: none;
    background: transparent;
    outline: none;
    width: auto;
}

.department-tag span {
    cursor: pointer;
    margin-left: 5px;
}
</style>
