<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/20/solid'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import { useAlertModalStore } from '@/Pages/Location/stores/useAlertModalStore'
import TextInput from '@/Components/TextInput.vue'
import { computed, ref } from 'vue'

const store = useLocationStore()
const alertStore = useAlertModalStore()

const newFloorName = ref('')
const canAdd = computed(() => newFloorName.value.trim().length > 0)

let addId = -1
const addFloor = () => {
    let exists = store.form.floors.findIndex(
        (d) =>
            d.name.trim().toLowerCase() ===
            newFloorName.value.trim().toLowerCase()
    )
    if (exists !== -1) {
        alertStore.title = 'Verdieping bestaat al'
        alertStore.message = 'Er bestaat al een verdieping met deze naam...'
        alertStore.positiveButtonText = 'Ok'
        alertStore.hasCancelButton = false
        alertStore.show()
        return
    }
    store.form.floors.unshift({
        name: newFloorName.value,
        id: addId,
        departments: [],
        numberOfDepartments: 0,
    })
    addId -= 1
    newFloorName.value = ''
}
</script>

<template>
    <div class="flex flex-col px-10">
        <div class="flex">
            <TextInput v-model="newFloorName" placeholder="Nieuwe verdieping" />
            <button
                type="button"
                @click="addFloor"
                :disabled="!canAdd"
                class="ml-1 mt-1.5 h-8 w-8 rounded-full enabled:bg-gradient disabled:cursor-not-allowed disabled:bg-gray-300"
                @keydown.enter="addFloor"
            >
                <PlusIcon
                    class="h-8 w-8 rounded-full p-1 align-middle text-white"
                />
            </button>
        </div>

        <FloorRow
            v-for="floor in store.form.floors"
            :floor="floor"
            :key="floor.id"
            class="mt-3"
        />
    </div>
</template>
