<script setup lang="ts">
import InputLabel from '@/Components/InputLabel.vue'
import InputError from '@/Components/InputError.vue'
import TextInput from '@/Components/TextInput.vue'
import { useLocationStore } from '@/Pages/Location/stores/useLocationStore'
import SwitchCheckBox from '@/Components/SwitchCheckBox.vue'

const store = useLocationStore()
</script>

<template>
    <div class="flex flex-col px-10">
        <div class="">
            <InputLabel for="elevators" value="Hoeveel liften zijn aanwezig" />
            <TextInput
                id="elevators"
                type="number"
                class="flex"
                v-model="store.form.elevators"
                required
                autocomplete="elevators"
                placeholder="Hoeveel liften zijn aanwezig"
            />

            <InputError class="mt-2" :message="store.form.errors.elevators" />
        </div>
        <div class="mt-4 flex flex-row space-x-6">
            <InputLabel
                for="elevatorsAreCentral"
                value="Zijn liften tov de afdelingen centraal ingericht"
            />
            <div class="my-auto flex justify-end">
                <SwitchCheckBox v-model="store.form.elevatorsAreCentral" />
            </div>
        </div>

        <div class="mt-4 flex flex-row space-x-6">
            <InputLabel
                for="freightElevator"
                value="Is er een goederenlift aanwezig"
            />
            <div class="my-auto flex justify-end">
                <SwitchCheckBox v-model="store.form.freightElevator" />
            </div>
        </div>

    </div>
</template>
